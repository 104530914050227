import React, { useState, useEffect, useContext } from 'react';
import { fetchAllCompanyTimeRecords, fetchAllCompanyTimeRecordsOfAMonth, getCompanyTimestamps, updateTimeRecordComplete } from '../../API/apiService';
import { accContext } from '../../App';
import { appContext } from '../../pages/Main';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';


const getMondayOfCurrentWeek = (): Date => {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(today.setDate(diff));
    return monday;
};

const EmployeesSummary: React.FC = () => {
    const employees = useContext(appContext);
    const [timeRecords, setTimeRecords] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [editedRecords, setEditedRecords] = useState<any[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<{ latitude: number; longitude: number } | null>(null);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
    const [selectedDate, setSelectedDate] = useState<Date | null>(getMondayOfCurrentWeek());
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    
    const accUser = useContext(accContext);
    const companyId = accUser.company_id;

    useEffect(() => {
        const fetchTimeRecords = async () => {
            if (companyId && selectedDate) {
                try {
                    setLoading(true);
                    
                    const { success, data } = await getCompanyTimestamps(companyId, 0, selectedDate);

                    if (success) {
                        setTimeRecords(data);
                    } else {
                        setError("Failed to fetch time records");
                    }
                } catch (error) {
                    setError("An error occurred while fetching time records");
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchTimeRecords();
    }, [companyId, selectedDate]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const changeEditTableMode = () => {
        setEditMode(!editMode);
        return editMode;
    };

    const saveChanges = async () => {
        setLoading(true);
        setEditMode(!editMode);
        
        try {
            for (let record of editedRecords) {
                console.log(record);
                await updateTimeRecordComplete(setLoading, record);
            }
            setError(''); // Limpiar errores previos
        } catch (error) {
            setError('Failed to save changes');
        } finally {
            setLoading(false);
        }
    };

    const formatTime = (timeArray, onClick) => {
        return timeArray ? timeArray.map((time, index) => {
            // Eliminar la parte de la zona horaria (que generalmente sigue un patrón `+03:00` o similar)
            const formattedTime = time.split(/[+-]/)[0].trim(); 
    
            return (
                <p key={index} onClick={() => onClick(index)} style={{ cursor: 'pointer', color: 'blue' }}>
                    {formattedTime}
                </p>
            );
        }) : '';
    };

    const formatTimeWithoutOnclick = (timeArray) => {        
        return timeArray ? timeArray.map((time, index) => (
            <p key={index} style={{ cursor: 'pointer', color: 'blue' }}>
                {time}
            </p>
        )) : '';
    };

    // Función para encontrar el nombre del empleado basado en el employee_id
    const getEmployeeName = (employeeId) => {
        const employee = employees.find(emp => emp.id === employeeId);
        return employee ? employee.name : 'Unknown Employee';
    };

    const downloadInExcelFormat = async () => {
        if (!companyId) return;

        setLoading(true);
        try {
            const { success, data } = await fetchAllCompanyTimeRecords(setLoading);

            if (success) {
                const formattedData = data.map(record => {
                    const employeeName = getEmployeeName(record.employee_id);

                    const days = [
                        { day: 'Lunes', in: record.monday_in, out: record.monday_out, rest_in: record.monday_rest_in, rest_out: record.monday_rest_out },
                        { day: 'Martes', in: record.tuesday_in, out: record.tuesday_out, rest_in: record.tuesday_rest_in, rest_out: record.tuesday_rest_out },
                        { day: 'Miércoles', in: record.wednesday_in, out: record.wednesday_out, rest_in: record.wednesday_rest_in, rest_out: record.wednesday_rest_out },
                        { day: 'Jueves', in: record.thursday_in, out: record.thursday_out, rest_in: record.thursday_rest_in, rest_out: record.thursday_rest_out },
                        { day: 'Viernes', in: record.friday_in, out: record.friday_out, rest_in: record.friday_rest_in, rest_out: record.friday_rest_out },
                        { day: 'Sábado', in: record.saturday_in, out: record.saturday_out, rest_in: record.saturday_rest_in, rest_out: record.saturday_rest_out },
                        { day: 'Domingo', in: record.sunday_in, out: record.sunday_out, rest_in: record.sunday_rest_in, rest_out: record.sunday_rest_out },
                    ];

                    return days.map(day => ({
                        'Empleado': employeeName,
                        'Día': day.day,
                        'Entrada': day.in?.join(', ') || '',
                        'Salida': day.out?.join(', ') || '',
                        'Comienzo de descanso': day.rest_in?.join(', ') || '',
                        'Fin de descanso': day.rest_out?.join(', ') || ''
                    }));
                }).flat();

                const worksheet = XLSX.utils.json_to_sheet(formattedData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Registros de Tiempo');
                XLSX.writeFile(workbook, `Registros_de_Tiempo_${companyId}.xlsx`);
            } else {
                setError('Failed to download Excel file');
            }
        } catch (error) {
            setError('An error occurred while downloading the Excel file');
        } finally {
            setLoading(false);
        }
    };

    const handleNextPage = () => {
        setSelectedDate((prevDate) => {
            if (!prevDate) return null;
            const nextWeek = new Date(prevDate);
            nextWeek.setDate(prevDate.getDate() + 7);
            return nextWeek;
        });
    };

    // Función para retroceder a la semana anterior
    const handlePrevPage = () => {
        setSelectedDate((prevDate) => {
            if (!prevDate) return null;
            const prevWeek = new Date(prevDate);
            prevWeek.setDate(prevDate.getDate() - 7);
            return prevWeek;
        });
    };

    const handleDateChange = async (date: Date | null) => {
        setSelectedDate(date);

        if (date && companyId) {
            try {
                setLoading(true);
                // Llamada a la API con la fecha seleccionada como concreteStartOfWeek
                const { success, data } = await getCompanyTimestamps(companyId, 0, date);

                if (success) {
                    setTimeRecords(data);
                } else {
                    setError('Failed to fetch time records');
                }
            } catch (error) {
                setError('An error occurred while fetching time records');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEditedRecordsChange = (newEditedRecords) => {
        console.log(newEditedRecords);
        setEditedRecords(newEditedRecords);
    };

    const fillExcelSheet = async (data, currentDate) => {
        // Cargar la plantilla del XLSX (suponiendo que has cargado el modelo)
        const response = await fetch(`${process.env.PUBLIC_URL}/Modelo-de-Registro-Jornada.xlsx`);
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
    
        console.log(employees);
        employees.forEach(employee => {
            // Clonar la plantilla para cada empleado
            // Crear una nueva hoja basada en la plantilla original
            const originalWorksheet = workbook.Sheets[workbook.SheetNames[0]];
            const originalData: string[][] = XLSX.utils.sheet_to_json(originalWorksheet, { header: 1 }) as string[][]; // Aseguramos que sea un array de arrays
            const newWorksheet = XLSX.utils.aoa_to_sheet(originalData); // Crear una nueva hoja a partir de esos datos
            const employeeName = employee.name;
            // Obtener el día de inicio de la semana para determinar dónde empezar (B9, C9, etc.)
            data.forEach((record, index) => {
                console.log('Pasa por data.forEach');
                console.log(record);
                if (record.employee_id === employee.id) {                    
                    console.log('startDay');
                    const dateString = record.week; // "2024-08-19"
                    const date = new Date(dateString); // Convertir la cadena a objeto Date

                    const startDay = date.getDate(); // Obtener el día del mes (1-31)

                    console.log(startDay); // Esto imprimirá el número del día del mes, por ejemplo, 19
                    console.log('baseRow');
                    const baseRow = 8 + startDay;  // El lunes empieza en B9, ajustamos según el día
                    const rowOffset = 0;  // Para separar registros de cada semana

                    newWorksheet[`B${baseRow + rowOffset}`] = { v: record.monday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset}`] = { v: record.monday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset}`] = { v: record.monday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset}`] = { v: record.monday_rest_out || '' };
            
                    newWorksheet[`B${baseRow + rowOffset + 1}`] = { v: record.tuesday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset + 1}`] = { v: record.tuesday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset + 1}`] = { v: record.tuesday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset + 1}`] = { v: record.tuesday_rest_out || '' };
            
                    newWorksheet[`B${baseRow + rowOffset + 2}`] = { v: record.wednesday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset + 2}`] = { v: record.wednesday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset + 2}`] = { v: record.wednesday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset + 2}`] = { v: record.wednesday_rest_out || '' };
            
                    newWorksheet[`B${baseRow + rowOffset + 3}`] = { v: record.thursday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset + 3}`] = { v: record.thursday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset + 3}`] = { v: record.thursday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset + 3}`] = { v: record.thursday_rest_out || '' };
            
                    newWorksheet[`B${baseRow + rowOffset + 4}`] = { v: record.friday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset + 4}`] = { v: record.friday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset + 4}`] = { v: record.friday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset + 4}`] = { v: record.friday_rest_out || '' };
            
                    newWorksheet[`B${baseRow + rowOffset + 5}`] = { v: record.saturday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset + 5}`] = { v: record.saturday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset + 5}`] = { v: record.saturday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset + 5}`] = { v: record.saturday_rest_out || '' };
            
                    newWorksheet[`B${baseRow + rowOffset + 6}`] = { v: record.sunday_in || '' };
                    newWorksheet[`C${baseRow + rowOffset + 6}`] = { v: record.sunday_out || '' };
                    newWorksheet[`D${baseRow + rowOffset + 6}`] = { v: record.sunday_rest_in || '' };
                    newWorksheet[`E${baseRow + rowOffset + 6}`] = { v: record.sunday_rest_out || '' };

                    // Agregar la nueva hoja al workbook con el nombre del empleado
                    console.log('book_append_sheet');
                    console.log('Termina');                       
                }
            });
            XLSX.utils.book_append_sheet(workbook, newWorksheet, employeeName);
        });
    
        // Guardar el archivo modificado
        XLSX.writeFile(workbook, `Modelo-de-Registro-de-Jornada_modificado.xlsx`);
        

        return workbook;
    };

    const convertExcelToPDF = async (workbook: any) => {
        const pdf = new jsPDF();
        
        // Iterar a través de todas las hojas en el workbook
        for (let sheetIndex = 0; sheetIndex < workbook.SheetNames.length; sheetIndex++) {
            const sheetName = workbook.SheetNames[sheetIndex];
            const worksheet = workbook.Sheets[sheetName];
    
            // Configurar el contenido inicial del PDF para cada hoja
            pdf.setFontSize(16);
            pdf.text(`REGISTRO DIARIO DE JORNADA - ${sheetName}`, 10, 10);
            
            // Extraer los datos directamente de las celdas del worksheet
            const header = ['Día', 'Hora Entrada', 'Hora Salida', 'Hora Entrada Descanso', 'Hora Salida Descanso'];
        
            const tableData = [];
            
            // Recorrer las filas que contienen los días (A9 en adelante)
            for (let row = 9; row <= 39; row++) {
                const day = worksheet[`A${row}`]?.v || '';  // Día
                const entryTime = worksheet[`B${row}`]?.v || '';  // Hora entrada
                const exitTime = worksheet[`C${row}`]?.v || '';  // Hora salida
                const restInTime = worksheet[`D${row}`]?.v || '';  // Hora entrada descanso
                const restOutTime = worksheet[`E${row}`]?.v || '';  // Hora salida descanso
        
                // Solo añadir la fila si hay datos relevantes (evitar filas completamente vacías)
                if (day || entryTime || exitTime || restInTime || restOutTime) {
                    tableData.push([day, entryTime, exitTime, restInTime, restOutTime]);
                }
            }
        
            console.log(tableData);
            
            // Generar la tabla en el PDF con los datos extraídos
            autoTable(pdf, {
                head: [header],
                body: tableData,
                startY: 30, // Espacio para el título
            });
    
            // Añadir una nueva página después de cada hoja, excepto la última
            if (sheetIndex < workbook.SheetNames.length - 1) {
                pdf.addPage();
            }
        }
    
        // Guardar el PDF
        pdf.save('Registros_Jornada.pdf');
    };

    const handleDownloadPDF = async () => {
        if (!companyId) return;
        
        setLoading(true);
    
        try {
            const { success, data } = await fetchAllCompanyTimeRecordsOfAMonth(setLoading, selectedDate.getFullYear(), selectedDate.getMonth(), employees); // Fetch data
            
            if (success) {
                // Paso 1: Rellenar el archivo XLSX
                const workbook = await fillExcelSheet(data, selectedDate); // Asegúrate de que fillExcelSheet devuelve el workbook
                
                // Paso 2: Convertir XLSX a PDF
                await convertExcelToPDF(workbook); // Pasar el workbook a convertExcelToPDF
    
            } else {
                setError('Failed to download PDF file');
            }
        } catch (error) {
            setError('An error occurred while downloading the PDF');
        } finally {
            setLoading(false);
        }
    };

    if (windowWidth < 1200) {
        return (
            <div className="employees-summary-card">
                <p>Sólo se puede visualizar la tabla de registros horarios de los empleados, en un ordenador, por favor, inténtelo de nuevo en dicho dispositivo.</p>
            </div>
        );
    }

    return (
        <div className="employees-summary-card">
            <h2>Registros de horas por semana</h2>
            {loading ? (
                <p>Cargando registros de horas...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
            <>
                {timeRecords.length > 0 ? (
                    <>
                        <div className='week-table-and-map'>
                        {editMode ? (
                            <WeekTableEditMode
                                timeRecords={timeRecords}
                                onEditedRecordsChange={handleEditedRecordsChange}
                            />
                        ) : (
                            <WeekTableViewMode
                                timeRecords={timeRecords}
                                formatTime={formatTime}
                                formatTimeWithoutOnclick={formatTimeWithoutOnclick}
                                setSelectedLocation={setSelectedLocation}
                            />
                        )}
                            {selectedLocation ? (
                                <div className="map-container">
                                    <iframe
                                        title="map"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        style={{ border: 0 }}
                                        src={`https://www.openstreetmap.org/export/embed.html?bbox=${selectedLocation.longitude - 0.005}%2C${selectedLocation.latitude - 0.005}%2C${selectedLocation.longitude + 0.005}%2C${selectedLocation.latitude + 0.005}&layer=mapnik&marker=${selectedLocation.latitude}%2C${selectedLocation.longitude}`}
                                        allowFullScreen
                                    />
                                    <br/>
                                    <a href={`https://www.openstreetmap.org/?mlat=${selectedLocation.latitude}&mlon=${selectedLocation.longitude}#map=18/${selectedLocation.latitude}/${selectedLocation.longitude}`}>Ver a pantalla completa</a>
                                </div>
                            ) : (
                                <div className="map-container">
                                    <iframe
                                        title="default-map"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        style={{ border: 0 }}
                                        src={`https://www.openstreetmap.org/export/embed.html?bbox=-180%2C-85%2C180%2C85&layer=mapnik`}
                                        allowFullScreen
                                    />
                                    <br/>
                                    <a href={`https://www.openstreetmap.org/#map=2/20.0/0.0`}>Ver a pantalla completa</a>
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <p>No existen registros de horas para esta semana.</p>
                )}
                <div className="pagination">
                    <button onClick={handlePrevPage} disabled={loading}>
                        Semana anterior
                    </button>
                    <button onClick={changeEditTableMode} disabled={editMode || accUser.rate_type === 1}>
                        Editar Tabla
                    </button>
                    <button onClick={saveChanges} disabled={!editMode}>
                        Guardar Tabla
                    </button>
                    <button onClick={downloadInExcelFormat} disabled={editMode}>
                        Descargar Excel
                    </button>
                    <button onClick={handleNextPage} disabled={loading}>
                        Semana siguiente
                    </button>
                    <button onClick={() => setShowDatePicker(!showDatePicker)}>
                        Seleccionar Fecha
                    </button>
                    <button onClick={handleDownloadPDF}>
                        Descargar en PDF
                    </button>
                </div>
                {showDatePicker && (
                    <div className="calendar-popup">
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            inline={true}
                            dateFormat="dd/MM/yyyy"
                            className="custom-datepicker"
                        />
                    </div>
                )}
            </>
            )}
        </div>
    );
};

const WeekTableEditMode = ({ timeRecords, onEditedRecordsChange  }) => {
    const employees = useContext(appContext);

    // Crear estado para las semanas agrupadas
    const [weeks, setWeeks] = useState(() => {
        const groupedWeeks = {};
        timeRecords.forEach(record => {
            const week = record.week;
            if (!groupedWeeks[week]) {
                groupedWeeks[week] = [];
            }
            groupedWeeks[week].push(record);
        });
        return groupedWeeks;
    });

    const handleInputChange = (week, recordIndex, dayIndex, field, value, idx) => {
        // Crear una copia de las semanas actuales
        const newWeeks = { ...weeks };

        switch (dayIndex) {
            case 0: // Lunes
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].monday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].monday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].monday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].monday_rest_out[idx] = value;
                        break;
                }
                break;
            case 1: // Martes
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].tuesday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].tuesday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].tuesday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].tuesday_rest_out = value;
                        break;
                }
                break;
            case 2: // Miércoles
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].wednesday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].wednesday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].wednesday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].wednesday_rest_out[idx] = value;
                        break;
                }
                break;
            case 3: // Jueves
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].thursday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].thursday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].thursday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].thursday_rest_out[idx] = value;
                        break;
                }
                break;
            case 4: // Viernes
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].friday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].friday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].friday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].friday_rest_out[idx] = value;
                        break;
                }
                break;
            case 5: // Sábado
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].saturday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].saturday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].saturday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].saturday_rest_out[idx] = value;
                        break;
                }
                break;
            case 6: // Domingo
                switch (field) {
                    case 'in':
                        newWeeks[week][recordIndex].sunday_in[idx] = value;
                        break;
                    case 'out':
                        newWeeks[week][recordIndex].sunday_out[idx] = value;
                        break;
                    case 'rest_in':
                        newWeeks[week][recordIndex].sunday_rest_in[idx] = value;
                        break;
                    case 'rest_out':
                        newWeeks[week][recordIndex].sunday_rest_out[idx] = value;
                        break;
                }
                break;
            default:
                break;
        }

        // Actualizar el estado con los nuevos valores
        setWeeks(newWeeks);
        onEditedRecordsChange(newWeeks[week]);

        console.log(weeks);
        console.log(newWeeks);
        
    };

    // Función para encontrar el nombre del empleado basado en el employee_id
    const getEmployeeName = (employeeId) => {
        const employee = employees.find(emp => emp.id === employeeId);
        return employee ? employee.name : 'Unknown Employee';
    };

    return (
        <div className="week-table">
            {Object.keys(weeks).map(weekKey => (
                <div key={weekKey}>
                    <h3>Semana del: {new Date(weekKey).toLocaleDateString()}</h3>
                    <div className='table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Empleado</th>
                                    <th>Día</th>
                                    <th>Entrada</th>
                                    <th>Salida</th>
                                    <th>Comienzo de descanso</th>
                                    <th>Fin de descanso</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weeks[weekKey].map((record, recordIndex) => {
                                    const days = [
                                        { name: 'Lunes', in: record.monday_in, out: record.monday_out, rest_in: record.monday_rest_in, rest_out: record.monday_rest_out, location_in: record.monday_clock_in_location, location_out: record.monday_clock_out_location },
                                        { name: 'Martes', in: record.tuesday_in, out: record.tuesday_out, rest_in: record.tuesday_rest_in, rest_out: record.tuesday_rest_out, location_in: record.tuesday_clock_in_location, location_out: record.tuesday_clock_out_location  },
                                        { name: 'Miércoles', in: record.wednesday_in, out: record.wednesday_out, rest_in: record.wednesday_rest_in, rest_out: record.wednesday_rest_out, location_in: record.wednesday_clock_in_location, location_out: record.wednesday_clock_out_location  },
                                        { name: 'Jueves', in: record.thursday_in, out: record.thursday_out, rest_in: record.thursday_rest_in, rest_out: record.thursday_rest_out, location_in: record.thursday_clock_in_location, location_out: record.thursday_clock_out_location  },
                                        { name: 'Viernes', in: record.friday_in, out: record.friday_out, rest_in: record.friday_rest_in, rest_out: record.friday_rest_out, location_in: record.friday_clock_in_location, location_out: record.friday_clock_out_location  },
                                        { name: 'Sábado', in: record.saturday_in, out: record.saturday_out, rest_in: record.saturday_rest_in, rest_out: record.saturday_rest_out, location_in: record.saturday_clock_in_location, location_out: record.saturday_clock_out_location  },
                                        { name: 'Domingo', in: record.sunday_in, out: record.sunday_out, rest_in: record.sunday_rest_in, rest_out: record.sunday_rest_out, location_in: record.sunday_clock_in_location, location_out: record.sunday_clock_out_location  },
                                    ];

                                    return days.map((day, dayIndex) => (
                                        <tr key={`${record.employee_id}-${day.name}-${dayIndex}`}>
                                            {dayIndex === 0 && (
                                                <td rowSpan={7}>{getEmployeeName(record.employee_id)}</td>
                                            )}
                                            <td>{day.name}</td>
                                        
                                            <td>
                                                {day.in?.map((time, idx) => (
                                                <input
                                                    key={`${record.employee_id}-${day.name}-in-${idx}`}
                                                    type="text"
                                                    value={time}
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'in', e.target.value, idx)}
                                                />
                                                )) || (
                                                <input
                                                    type="text"
                                                    value=""
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'in', e.target.value, 0)}
                                                />
                                                )}
                                            </td>
                                        
                                            <td>
                                                {day.out?.map((time, idx) => (
                                                <input
                                                    key={`${record.employee_id}-${day.name}-out-${idx}`}
                                                    type="text"
                                                    value={time}
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'out', e.target.value, idx)}
                                                />
                                                )) || (
                                                <input
                                                    type="text"
                                                    value=""
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'out', e.target.value, 0)}
                                                />
                                                )}
                                            </td>
                                        
                                            <td>
                                                {day.rest_in?.map((time, idx) => (
                                                <input
                                                    key={`${record.employee_id}-${day.name}-rest_in-${idx}`}
                                                    type="text"
                                                    value={time}
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'rest_in', e.target.value, idx)}
                                                />
                                                )) || (
                                                <input
                                                    type="text"
                                                    value=""
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'rest_in', e.target.value, 0)}
                                                />
                                                )}
                                            </td>
                                        
                                            <td>
                                                {day.rest_out?.map((time, idx) => (
                                                <input
                                                    key={`${record.employee_id}-${day.name}-rest_out-${idx}`}
                                                    type="text"
                                                    value={time}
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'rest_out', e.target.value, idx)}
                                                />
                                                )) || (
                                                <input
                                                    type="text"
                                                    value=""
                                                    onChange={(e) => handleInputChange(weekKey, recordIndex, dayIndex, 'rest_out', e.target.value, 0)}
                                                />
                                                )}
                                            </td>
                                        </tr>
                                    ));
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
};

const WeekTableViewMode = ({ timeRecords, formatTime, formatTimeWithoutOnclick, setSelectedLocation }) => {
    const employees = useContext(appContext);

    // Agrupar registros de tiempo por semana y empleado
    const weeks = {};
    timeRecords.forEach(record => {
        const week = record.week;
        if (!weeks[week]) {
            weeks[week] = [];
        }
        weeks[week].push(record);
    });

    // Función para encontrar el nombre del empleado basado en el employee_id
    const getEmployeeName = (employeeId) => {
        const employee = employees.find(emp => emp.id === employeeId);
        return employee ? employee.name : 'Unknown Employee';
    };

    return (
        <div className="week-table">
            {Object.keys(weeks).map(weekKey => (
                <div key={weekKey}>
                    <h3>Semana del: {new Date(weekKey).toLocaleDateString()}</h3>
                    <div className='table'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Empleado</th>
                                    <th>Día</th>
                                    <th>Entrada</th>
                                    <th>Salida</th>
                                    <th>Comienzo de descanso</th>
                                    <th>Fin de descanso</th>
                                </tr>
                            </thead>
                            <tbody>
                                {weeks[weekKey].map((record, recordIndex) => {
                                    const days = [
                                        { name: 'Lunes', in: record.monday_in, out: record.monday_out, rest_in: record.monday_rest_in, rest_out: record.monday_rest_out, location_in: record.monday_clock_in_location, location_out: record.monday_clock_out_location },
                                        { name: 'Martes', in: record.tuesday_in, out: record.tuesday_out, rest_in: record.tuesday_rest_in, rest_out: record.tuesday_rest_out, location_in: record.tuesday_clock_in_location, location_out: record.tuesday_clock_out_location  },
                                        { name: 'Miércoles', in: record.wednesday_in, out: record.wednesday_out, rest_in: record.wednesday_rest_in, rest_out: record.wednesday_rest_out, location_in: record.wednesday_clock_in_location, location_out: record.wednesday_clock_out_location  },
                                        { name: 'Jueves', in: record.thursday_in, out: record.thursday_out, rest_in: record.thursday_rest_in, rest_out: record.thursday_rest_out, location_in: record.thursday_clock_in_location, location_out: record.thursday_clock_out_location  },
                                        { name: 'Viernes', in: record.friday_in, out: record.friday_out, rest_in: record.friday_rest_in, rest_out: record.friday_rest_out, location_in: record.friday_clock_in_location, location_out: record.friday_clock_out_location  },
                                        { name: 'Sábado', in: record.saturday_in, out: record.saturday_out, rest_in: record.saturday_rest_in, rest_out: record.saturday_rest_out, location_in: record.saturday_clock_in_location, location_out: record.saturday_clock_out_location  },
                                        { name: 'Domingo', in: record.sunday_in, out: record.sunday_out, rest_in: record.sunday_rest_in, rest_out: record.sunday_rest_out, location_in: record.sunday_clock_in_location, location_out: record.sunday_clock_out_location  },
                                    ];

                                    return days.map((day, dayIndex) => (
                                        <tr key={`${record.employee_id}-${day.name}-${dayIndex}`}>
                                            {dayIndex === 0 && (
                                                <td rowSpan={7}>{getEmployeeName(record.employee_id)}</td>
                                            )}
                                            <td>{day.name}</td>
                                            <td className="day">
                                                {formatTime(day.in, (index) => {
                                                    if (day.location_in && day.location_in[index]) {
                                                        setSelectedLocation(day.location_in[index]);
                                                    } else {
                                                        setSelectedLocation(null);
                                                    }
                                                })}
                                            </td>
                                            <td className="day">
                                                {formatTime(day.out, (index) => {
                                                    if (day.location_out && day.location_out[index]) {
                                                        setSelectedLocation(day.location_out[index]);
                                                    } else {
                                                        setSelectedLocation(null);
                                                    }
                                                })}
                                            </td>
                                            <td className="day">{formatTimeWithoutOnclick(day.rest_in)}</td>
                                            <td className="day">{formatTimeWithoutOnclick(day.rest_out)}</td>
                                        </tr>
                                    ));
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EmployeesSummary;
