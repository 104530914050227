import { Department } from '../interfaces/department';
import { userInterface } from '../interfaces/interface';
import { stripe, supabase } from './supabaseClient';

// Define las interfaces si aún no las tienes
interface Salary {
    id: number;
    amount: number;
    // Agrega otros campos relevantes
}

interface Hours {
    id: number;
    total_hours: number;
    // Agrega otros campos relevantes
}

interface AvgHours {
    id: number;
    average_hours: number;
    // Agrega otros campos relevantes
}

interface PasswordRecoveryData {
    email: string;
    password: string;
}

interface LoginData {
    username: string;
    password: string;
}

// Función para fetch Departments filtrados por company_id
export const fetchDepartments = async (company_id: number): Promise<Department[]> => {
    const { data, error } = await supabase
        .from('departments')
        .select('*')
        .eq('company_id', company_id); // Filtra por company_id
    
    if (error) {
        console.error('Error fetching departments:', error.message);
        return [];
    }
    
    return data as Department[];
};

export const getDepartmentById = async (id: number, isAdmin: boolean): Promise<string> => {
    try {
        if (isAdmin) {
            return "Admin";
        }

        const { data, error } = await supabase
            .from('departments')
            .select('name')
            .eq('id', id)
            .single();

        if (error) {
            console.error("Error fetching department:", error);
            return "Sin departamento asignado";
        }

        return data?.name || "Sin departamento asignado";
    } catch (error) {
        console.error("Error:", error);
        return "Sin departamento asignado";
    }
};

// Función para obtener el salario
export const getSalary = async (id: number = 1): Promise<Salary | null> => {
    const { data, error } = await supabase
        .from('salary') // Asegúrate de que este es el nombre correcto de la tabla
        .select('*')
        .eq('id', id)
        .single(); // Espera un solo registro
    
    if (error) {
        console.error('Error fetching salary:', error.message);
        return null;
    }

    return data;
};

// Función para obtener las horas totales
export const getHours = async (id: number = 1): Promise<Hours | null> => {
    const { data, error } = await supabase
        .from('hours') // Asegúrate de que este es el nombre correcto de la tabla
        .select('*')
        .eq('id', id)
        .single(); // Espera un solo registro
    
    if (error) {
        console.error('Error fetching total hours:', error.message);
        return null;
    }

    return data;
};

// Función para obtener las horas promedio
export const getAvgHours = async (id: number = 1): Promise<AvgHours | null> => {
    const { data, error } = await supabase
        .from('average_hours') // Asegúrate de que este es el nombre correcto de la tabla
        .select('*')
        .eq('id', id)
        .single(); // Espera un solo registro

    if (error) {
        console.error('Error fetching average hours:', error.message);
        return null;
    }

    return data;
};

// Función para agregar un empleado
export const addEmployee = async (employee: userInterface): Promise<any> => {
    try {
        // Si el department_id es 0, creamos un nuevo departamento
        if (employee.department_id === 0) {
            console.log('Se crea employee?');
            console.log(employee);
            const department = await createDepartment(employee.department_name, employee.company_id);
            console.log('Se crea department_id?');
            console.log(department.id);
            if (department.id) {
                employee.department_id = department.id;
            } else {
                throw new Error("Failed to create department.");
            }
        }

        const { data: userData } = await supabase
            .from('users') // Asegúrate de que este es el nombre correcto de la tabla de usuarios
            .select('*')
            .eq('email', employee.email)
            .single();

        if (!userData) {
            // Registrar usuario
            await registerUser(employee.email, employee.password);
        }

        // Remover employee_name antes de la inserción
        const { password, department_name, ...employeeData } = employee;

        // Insertar el empleado en la base de datos
        const { data, error } = await supabase
            .from('employees') // Asegúrate de que este es el nombre correcto de la tabla
            .insert([employeeData])
            .select('*')
            .single();

        if (error) {
            console.error('Error adding employee:', error.message);
            return null;
        }

        console.log(data);
        
        return data;
    } catch (error) {
        console.error('Error in addEmployee function:', error.message);
        return null;
    }
};

// Función para la recuperación de contraseñas
export const recoverPassword = async (data: PasswordRecoveryData): Promise<any> => {
    const { email, password } = data;

    // Buscar al usuario por email
    const { data: userData, error: userError } = await supabase
        .from('users') // Asegúrate de que este es el nombre correcto de la tabla de usuarios
        .select('*')
        .eq('email', email)
        .single();
    
    if (userError || !userData) {
        console.error('User not found:', userError?.message);
        return { success: false, message: 'User not found' };
    }

    // Actualizar la contraseña del usuario
    const { error: updateError } = await supabase
        .from('users') // Asegúrate de que este es el nombre correcto de la tabla de usuarios
        .update({ password })
        .eq('email', email);
    
    if (updateError) {
        console.error('Error updating password:', updateError.message);
        return { success: false, message: 'Error updating password' };
    }

    return { success: true, message: 'Password updated successfully' };
};

// Función para iniciar sesión
export const loginUser = async (data: LoginData): Promise<any> => {
    const { username, password } = data;
    
    const { data: userData, error } = await supabase.auth.signInWithPassword({
        email: username,
        password: password,
    });

    if (error) {
        console.error('Error logging in:', error.message);
        return { success: false, message: 'Login failed' };
    }

    if (userData.user) {

        const { id: userId } = userData.user;

        // Obtén el employee asociado al usuario
        const { data: employeeData, error: employeeError } = await supabase
            .from('employees')
            .select('*')
            .eq('auth_user_id', userId) // Asumiendo que 'user_id' es la columna que relaciona usuario con empleado
            .single();

        if (employeeError) {
            console.error('Error fetching employee:', employeeError.message);
            return { success: false, message: 'Failed to fetch employee data' };
        }

        return { success: true, data: { ...userData, employee: employeeData } };
    }

    return { success: false, message: 'No user data found' };
};

// Función para actualizar la información del empleado
export const updateEmployee = async (userId: number, data: userInterface): Promise<any> => {
    const { data: updatedUser, error } = await supabase
        .from('employees')
        .update(data)
        .eq('id', userId)
        .single();

    if (error) {
        console.error('Error updating user:', error.message);
        return { success: false, message: 'Update failed' };
    }

    return { success: true, data: updatedUser };
};

// Función para actualizar la ipassword de un empleado
export const changeUserPassword = async (userId: number, password: string): Promise<any> => {
    const { data: updatedUser } = await supabase.auth.updateUser({
        password,
    });

    return { success: true, data: updatedUser };
};

// Función para actualizar la información del usuario
export const updateUser = async (userId: number, data: userInterface): Promise<any> => {
    const { data: updatedUser, error } = await supabase
        .from('users') // Asegúrate de que este es el nombre correcto de la tabla de usuarios
        .update(data)
        .eq('id', userId)
        .single();

    if (error) {
        console.error('Error updating user:', error.message);
        return { success: false, message: 'Update failed' };
    }

    return { success: true, data: updatedUser };
};

// Función para obtener todos los usuarios
export const fetchUsers = async (): Promise<any> => {
    const { data: users, error } = await supabase
        .from('users') // Asegúrate de que este es el nombre correcto de la tabla de usuarios
        .select('*');
    
    if (error) {
        console.error('Error fetching users:', error.message);
        return { success: false, message: 'Fetch failed' };
    }

    return { success: true, data: users };
};

/// Función para obtener timestamps paginados del usuario por companyId
export const getCompanyTimestamps = async (companyId: number, weekOffset: number, concreteStartOfWeek: Date = null): Promise<any> => {
    try {
        // Paso 1: Obtener los IDs de los empleados de la compañía
        const { data: employees, error: employeesError } = await supabase
            .from('employees')
            .select('id')
            .eq('company_id', companyId);

        if (employeesError) {
            throw new Error(`Error fetching employees: ${employeesError.message}`);
        }

        if (!employees || employees.length === 0) {
            return { success: true, data: [] }; // No hay empleados para la compañía, retornar datos vacíos
        }

        // Extraer los IDs de empleados
        const employeeIds = employees.map(employee => employee.id);

        // Paso 2: Calcular el rango de fechas para la semana deseada
        let currentDate;
        if (!concreteStartOfWeek) {
            currentDate = new Date();
        }
        else {
            currentDate = concreteStartOfWeek;
        }
        const startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1 - (weekOffset * 7)));
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        // Formatear las fechas como 'yyyy-mm-dd'
        const formatDate = (date: Date) => date.toISOString().split('T')[0];

        const startDate = formatDate(startOfWeek);
        const endDate = formatDate(endOfWeek);

        // Paso 3: Obtener los timestamps correspondientes a los IDs de empleados y la semana específica
        console.log('Consulta timestamps');
        
        const { data: timestamps, error: timestampsError } = await supabase
            .from('timerecords')
            .select('*')
            .in('employee_id', employeeIds)
            .gte('week', startDate)
            .lte('week', endDate);
        
        console.log('Consulta timestamps OK');

        if (timestampsError) {
            throw new Error(`Error fetching timestamps: ${timestampsError.message}`);
        }

        return { success: true, data: timestamps };
    } catch (error) {
        console.error(error.message);
        return { success: false, message: error.message };
    }
};

// Función para obtener timestamps del usuario
export const getTimestamps = async (userId: number): Promise<any> => {    
    const { data, error } = await supabase
        .from('timerecords') // Asegúrate de que este es el nombre correcto de la tabla de timestamps
        .select('*')
        .eq('user_id', userId)
        .single();
    
    if (error) {
        console.error('Error fetching timestamps:', error.message);
        return { success: false, message: 'Fetch failed' };
    }
    console.log('Pasa con ' + data);
    return { success: true, data };
};

// Función para obtener empleados filtrados
export const fetchEmployees = async (companyId: number, isAdmin: boolean): Promise<any> => {
    if (isAdmin) {
        const { data, error } = await supabase
            .from('employees')
            .select('*')
            .eq('company_id', companyId);
        if (error) {
            console.error('Error fetching employees:', error.message);
            return { success: false, message: 'Fetch failed' };
        }
        return { success: true, data };
    }
    else {
        return { success: true, data: [] };
    }

};

// Función para eliminar un usuario
export const deleteUser = async (id: number): Promise<any> => {
    try {
        // Paso 1: Obtener el auth_user_id del empleado antes de eliminarlo
        const { data: employeeData, error: fetchError } = await supabase
            .from('employees')
            .select('*')
            .eq('id', id)
            .single();

        if (fetchError || !employeeData) {
            console.error('Error fetching employee:', fetchError?.message);
            return { success: false, message: 'Employee not found' };
        }

        // Paso 2: Eliminar al empleado de la tabla employees
        if (!employeeData.admin) {
            const { data, error: deleteError } = await supabase
                .from('employees')
                .delete()
                .eq('id', id);

            if (deleteError) {
                console.error('Error deleting employee:', deleteError.message);
                return { success: false, message: 'Delete failed' };
            }

            return { success: true, data };
        }
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Unexpected error occurred' };
    }
};

// Función para actualizar un timestamp de usuario
export const patchTimestamp = async (id: number, formData: userInterface): Promise<any> => {
    const { data, error } = await supabase
        .from('employees')
        .update(formData)
        .eq('id', id);
    
    if (error) {
        console.error('Error updating timestamp:', error.message);
        return { success: false, message: 'Update failed' };
    }
    return { success: true, data };
};

// Obtener registros de tiempo
export const getTimeRecord = async (id: number): Promise<any> => {
    const { data, error } = await supabase
        .from('timerecords')
        .select('*')
        .eq('employee_id', id);
    if (error) {
        console.error('Error fetching time records:', error.message);
        return { success: false, message: 'Fetch failed' };
    }
    return { success: true, data };
};

// Obtener la fecha del lunes de la semana actual en formato yyyy-mm-dd
const getWeekStartDate = (): string => {
    const today = new Date();
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1); // Lunes de esta semana
    const monday = new Date(today.setDate(diff));
    const year = monday.getFullYear();
    const month = (monday.getMonth() + 1).toString().padStart(2, '0');
    const date = monday.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${date}`;
};

// Obtener el nombre del día de la semana en formato textual
const getDayOfWeek = (date: Date): string => {
    const day = date.getDay();
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[day];
};

// Formatear el tiempo a string con zona horaria (Ejemplo: "08:00:00+02")
const formatTime = (time: Date): string => {
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    const seconds = time.getSeconds().toString().padStart(2, '0');
    const timezoneOffset = -time.getTimezoneOffset() / 60; // Offset en horas
    const timezone = (timezoneOffset >= 0 ? '+' : '-') + Math.abs(timezoneOffset).toString().padStart(2, '0'); // Formato de zona horaria
    return `${hours}:${minutes}:${seconds}${timezone}`;
    // return `${hours}:${minutes}:${seconds}`;
};

const getCurrentLocation = (): Promise<{ latitude: number; longitude: number }> => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
};

export const updateTimeRecord = async (setLoading: (loading: boolean) => void, id: number, record: { [key: string]: any }, action: 'in' | 'out'): Promise<any> => {
    setLoading(true);
    const weekStartDate = getWeekStartDate(); // Obtener el lunes de la semana actual en formato yyyy-mm-dd
    const today = new Date();

    // Obtener el UUID del usuario de localStorage
    const employeeDataJson = localStorage.getItem('employeeData'); // Obtener el JSON de localStorage
    const employeeData = employeeDataJson ? JSON.parse(employeeDataJson) : null;
    const employeeIdUuid = employeeData?.auth_user_id; // Asumiendo que el UUID está almacenado en auth_user_id

    if (!employeeIdUuid) {
        console.error('No UUID found in localStorage.');
        return { success: false, message: 'UUID not found in localStorage' };
    }

    // Obtener la ubicación geográfica actual
    const location = await getCurrentLocation(); // Implementa esta función según tus necesidades

    // Construir el campo específico para actualizar o insertar
    const dayOfWeek = getDayOfWeek(today);
    const fieldKey = `${dayOfWeek}_${action === 'in' ? 'in' : 'out'}`;
    const locationFieldKey = `${dayOfWeek}_${action === 'in' ? 'clock_in_location' : 'clock_out_location'}`;
    const timestamp = formatTime(today);

    // Verificar si record contiene el campo requerido
    if (!record[fieldKey]) {
        console.error('No value provided for field:', fieldKey);
        return { success: false, message: 'No value for field' };
    }

    try {
        // Primero, buscar si ya existe una fila con la combinación de `week` y `employee_id`
        const { data: existingRecord, error: fetchError } = await supabase
            .from('timerecords')
            .select('*')
            .eq('employee_id', id)
            .eq('week', weekStartDate)
            .single();

        if (fetchError && fetchError.message !== 'No rows found') {
            console.error('Error fetching time record:', fetchError.message);
        }

        // Determinar si se puede actualizar
        const canUpdate = existingRecord && (
            (action === 'in' && !existingRecord.is_working) || 
            (action === 'out' && existingRecord.is_working)
        );

        if (canUpdate) {
            // Si existe y cumple con la condición, actualizamos solo la columna específica
            const updatedFields = {
                [fieldKey]: [...(existingRecord ? existingRecord[fieldKey] || [] : []), timestamp],
                [locationFieldKey]: [...(existingRecord ? existingRecord[locationFieldKey] || [] : []), location],
                is_working: action === 'in' // Si la acción es 'in', poner is_working a true, de lo contrario, false
            };
            
            const { data: updatedData, error: updateError } = await supabase
                .from('timerecords')
                .update(updatedFields)
                .eq('employee_id', id)
                .eq('week', weekStartDate);

            if (updateError) {
                console.error('Error updating time record:', updateError.message);
                return { success: false, message: 'Update failed' };
            }

            return { success: true, data: updatedData };
        } else if (!existingRecord) {
            // Si no existe, creamos una nueva fila
            const newRecord = { 
                employee_id: id, 
                employee_id_uuid: employeeIdUuid, // Añadir UUID del usuario
                week: weekStartDate, 
                [fieldKey]: [timestamp],
                [locationFieldKey]: [location],
                is_working: action === 'in' // Si la acción es 'in', poner is_working a true, de lo contrario, false
            };

            const { data: newData, error: createError } = await supabase
                .from('timerecords')
                .insert([newRecord]);

            if (createError) {
                console.error('Error creating new time record:', createError.message);
                return { success: false, message: 'Create failed' };
            }

            return { success: true, data: newData };
        }
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Unexpected error' };
    }
    finally {
        setLoading(false);
    }
};

export const updateRestRecord = async (setLoading: (loading: boolean) => void, id: number, restData: { [key: string]: string[] }, action: 'rest_in' | 'rest_out'): Promise<any> => {
    setLoading(true);
    const weekStartDate = getWeekStartDate(); // Obtener el lunes de la semana actual en formato yyyy-mm-dd
    const today = new Date();
    const dayOfWeek = getDayOfWeek(today); // Obtiene el día de la semana actual
    const fieldKey = `${dayOfWeek}_${action}`;
    const locationFieldKey = `${dayOfWeek}_${action}_location`;
    const timestamp = formatTime(today); // Asegúrate de que el formato sea HH:MM:SS+TZ

    // Obtener la ubicación geográfica actual
    const location = await getCurrentLocation(); // Implementa esta función según tus necesidades

    try {
        // Primero, buscar si ya existe una fila con la combinación de `week` y `employee_id`
        const { data: existingRecord, error: fetchError } = await supabase
            .from('timerecords')
            .select('*')
            .eq('employee_id', id)
            .eq('week', weekStartDate)
            .single();

        if (fetchError && fetchError.message !== 'No rows found') {
            console.error('Error fetching time record:', fetchError.message);
            return { success: false, message: 'Failed to fetch record' };
        }

        // Determinar si se puede actualizar
        const canUpdate = (
            (action === 'rest_in' && (!existingRecord || !existingRecord.is_resting)) || 
            (action === 'rest_out' && existingRecord && existingRecord.is_resting)
        );

        if (canUpdate) {
            if (existingRecord) {
                // Si existe, actualizamos solo la columna específica
                const existingTimestamps = existingRecord[fieldKey] || [];
                const updatedRestData = [...existingTimestamps, timestamp]; // Añadir el nuevo timestamp
                const existingLocations = existingRecord[locationFieldKey] || [];
                const updatedRestLocations = [...existingLocations, location]; // Añadir la nueva ubicación

                const { data: updatedData, error: updateError } = await supabase
                    .from('timerecords')
                    .update({ 
                        [fieldKey]: updatedRestData,
                        [locationFieldKey]: updatedRestLocations,
                        is_resting: action === 'rest_in' // Actualiza `is_resting` a true si `rest_in`, de lo contrario a false
                    })
                    .eq('employee_id', id)
                    .eq('week', weekStartDate);

                if (updateError) {
                    console.error('Error updating rest record:', updateError.message);
                    return { success: false, message: 'Update failed' };
                }

                return { success: true, data: updatedData };
            } else {
                // Crear un nuevo registro si no existe
                const newRecord = { 
                    employee_id: id, 
                    week: weekStartDate, 
                    [fieldKey]: [timestamp],
                    [locationFieldKey]: [location],
                    is_resting: action === 'rest_in' // Si `rest_in`, poner `is_resting` a true, de lo contrario a false
                };
                
                const { data: newData, error: createError } = await supabase
                    .from('timerecords')
                    .insert([newRecord]);

                if (createError) {
                    console.error('Error creating new rest record:', createError.message);
                    return { success: false, message: 'Create failed' };
                }

                return { success: true, data: newData };
            }
        } else {
            return { success: false, message: 'Update not allowed based on current state' };
        }
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Unexpected error' };
    }
    finally {
        setLoading(false);
    }
};

export const updateTimeRecordComplete = async (
    setLoading: (loading: boolean) => void,
    timeRecord: any
): Promise<any> => {
    setLoading(true);

    try {
        const newTimeRecord = [timeRecord];
        // Supabase: Update the row in the "timerecords" table where the id matches timeRecord.id
        const { data, error } = await supabase
            .from('timerecords')
            .update(newTimeRecord)
            .eq('id', Number(timeRecord.id))
            .select('*');

        if (error) {
            console.error('Error updating record:', error.message);
            return { success: false, message: error.message };
        }

        return { success: true, message: 'Record updated successfully', data };
    } catch (error) {
        console.error('Unexpected error:', error);
        return { success: false, message: 'Unexpected error' };
    } finally {
        setLoading(false);
    }
};

export const fetchAllCompanyTimeRecords = async (setLoading: (loading: boolean) => void): Promise<any> => {
    setLoading(true);

    try {
        // Realizar la consulta para obtener todos los registros de tiempo de la tabla 'timerecords'
        const { data, error } = await supabase
            .from('timerecords')
            .select('*');

        if (error) {
            console.error('Error fetching time records:', error.message);
            setLoading(false);
            return { success: false, message: 'Failed to fetch time records' };
        }

        setLoading(false);
        return { success: true, data };
    } catch (error) {
        console.error('Unexpected error:', error);
        setLoading(false);
        return { success: false, message: 'Unexpected error' };
    }
};

const getFirstAndLastDayOfMonth = (year: number, month: number) => {
    // Obtener el primer día del mes
    const firstDay = new Date(year, month, 1);

    // Obtener el último día del mes
    const lastDay = new Date(year, month + 1, 0);

    // Convertir a formato yyyy-mm-dd
    const firstDayStr = firstDay.toISOString().split('T')[0];
    const lastDayStr = lastDay.toISOString().split('T')[0];

    return { firstDayStr, lastDayStr };
};

export const fetchAllCompanyTimeRecordsOfAMonth = async (
    setLoading: (loading: boolean) => void,
    year: number,
    month: number,
    employees: userInterface[] // Array de objetos con employee_id
  ): Promise<any> => {
    setLoading(true);
  
    try {
        // Obtener los ids de los empleados
        const employeeIds = employees.map(emp => emp.id);

        // Obtener el primer y último día del mes
        const { firstDayStr, lastDayStr } = getFirstAndLastDayOfMonth(year, month);

        // Filtrar los registros de los empleados que estén dentro del mes
        const { data, error } = await supabase
            .from('timerecords')
            .select('*')
            .in('employee_id', employeeIds) // Filtrar por los ids de los empleados
            .gte('week', firstDayStr) // Filtrar por los días que sean >= al primer día del mes
            .lte('week', lastDayStr); // Filtrar por los días que sean <= al último día del mes

        if (error) {
            console.error('Error fetching time records:', error.message);
            setLoading(false);
            return { success: false, message: 'Failed to fetch time records' };
        }
    
        setLoading(false);
        return { success: true, data };
    } catch (error) {
        console.error('Unexpected error:', error);
        setLoading(false);
        return { success: false, message: 'Unexpected error' };
    }
  };

// Función para registrar un usuario
export const registerUser = async (email: string, password: string) => {
    const { data: userData, error } = await supabase.auth.signUp({
        email,
        password,
    });

    if (error) {
        throw new Error(error.message);
    }

    return userData;
};

// Función para crear una empresa
export const createCompany = async (name: string) => {
    const { data, error } = await supabase
        .from('companies')
        .insert([{ name }])
        .select('*')
        .single();

    if (error) {
        throw new Error(error.message);
    }

    return data;
};

// Función para crear un empleado
export const createEmployee = async (
    name: string,
    username: string,
    email: string,
    password: string,
    auth_user_id: string,
    company_id: number,
    admin: boolean
    ) => {
    const { data, error } = await supabase
        .from('employees')
        .insert([{
        name,
        username,
        email,
        password_hash: password, // Debes cifrar esta contraseña en un entorno real
        auth_user_id,
        company_id,
        admin,
        }])
        .select('*')
        .single();

    if (error) {
        throw new Error(error.message);
    }

    return data;
};

// Función para crear un departamento
export const createDepartment = async (name: string, company_id: number) => {
    if (name) {
        const { data, error } = await supabase
            .from('departments')
            .insert([{ name, company_id }])
            .select('*');
    
        if (error) {
            throw new Error(error.message);
        }
    
        // Devuelve el primer (y único) departamento insertado
        return data ? data[0] : null;
    }
};

// Función para crear múltiples departamentos
export const createDepartments = async (departments: Department[], company_id: number) => {
    const departmentPromises = departments.map((department) =>
        createDepartment(department.name, company_id)
    );

    await Promise.all(departmentPromises);
};

// Función para crear múltiples empleados
export const createEmployees = async (employees: any[], company_id: number) => {
    const employeePromises = employees.map(async (employee, index) => {
        // const price = index >= 12 ? 9.99 : index >= 8 ? 6.99 : index >= 4 ? 3.99 : 0.0;

        const password = generateRandomString();
        registerUser(employee.email, password);
        const userData = await registerUser(employee.email, password);
        createEmployee(employee.name, employee.name, employee.email, generateRandomString(), userData.user.id, company_id, false);
    });

    await Promise.all(employeePromises);
};

// Función para generar una cadena aleatoria de 8 caracteres
function generateRandomString(length = 8) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// Función para obtener el customerId de un cliente de Stripe basado en el employee_id en su metadata
export const getCustomerIdByEmployeeId = async (employeeId: number) => {
    try {
        // Listar todos los clientes de Stripe
        const customers = await stripe.customers.list({
            limit: 100,  // Limitar a 100 por solicitud. Aumenta si es necesario.
        });

        // Filtrar clientes con el employee_id correspondiente en la metadata
        const customer = customers.data.find(c => c.metadata?.employee_id === employeeId.toString());

        if (customer) {
            return customer.id;  // Retornar el customerId si se encuentra
        } else {
            throw new Error('No customer found for the given employee_id');
        }
    } catch (error) {
        console.error('Error finding customer by employee_id:', error);
        throw error;
    }
};

// Función para crear una sesión de pago en tu backend
export const createCheckoutSession = async (productId: string, userId: number, email: string, rateType: number) => {
    try {
        // Busca o crea un cliente en Stripe
        let customer = await stripe.customers.list({
            email,
            limit: 1,
        });

        if (!customer.data.length) {
            // Si no existe, crear un nuevo Customer
            customer = await stripe.customers.create({
                email: email,
                metadata: {
                    employee_id: userId,
                },
            });
        } else {
            customer = customer.data[0];
        }

        // Obtén los precios asociados al producto
        const prices = await stripe.prices.list({
            product: productId,
            limit: 1,  // Asumiendo que solo tienes un precio asociado al producto
        });

        // Asegúrate de que existe un precio para el producto
        if (!prices.data.length) {
            throw new Error('No price found for this product');
        }

        const priceId = prices.data[0].id;  // Obtén el primer priceId

        // Crear la sesión de Checkout con el priceId
        const session = await stripe.checkout.sessions.create({
            payment_method_types: ['card'],
            line_items: [{
                price: priceId, // Ahora usamos el priceId en lugar del productId
                quantity: 1,
            }],
            mode: 'subscription',
            customer: customer.id,
            success_url: `https://jornadalaboral.app/payment-success?userId=${userId}&customerId=${customer.id}`,
            cancel_url: 'https://jornadalaboral.app/home',
        });

        // Retornar la URL de la sesión de pago
        return session.url;
        
    } catch (error) {
        console.error('Error:', error);
        throw error;  // Opcional: lanzar el error para manejarlo fuera de la función
    }
};

// Función para obtener la suscripción activa del usuario
export const getActiveSubscription = async (customerId: string, userId: number) => {
    try {
        // Buscar suscripciones activas del cliente
        const subscriptions = await stripe.subscriptions.list({
            customer: customerId,
            status: 'active', // Filtrar solo las activas
            limit: 1,
        });

        // Busca el empleado con auth_user_id igual a userId
        const { data: employee, error: employeeError } = await supabase
            .from('employees')  // Asegúrate de que este sea el nombre correcto de la tabla de empleados
            .select('*')
            .eq('id', userId)
            .single();

        // Si hay una suscripción activa, retornar el ID
        if (subscriptions.data.length > 0) {
            // Actualiza el campo rate_selected a true para este empleado
            await supabase.from('employees').update({ rate_type: employee.rate_type, rate_selected: true}).eq('id', userId);
            return subscriptions.data[0];
        }

        if (employeeError || !employee) {
            console.error('Error fetching employee:', employeeError);
            return { success: false, error: employeeError || 'Employee not found' };
        }

        // Actualiza el campo rate_selected a true para este empleado
        const { data: updatedEmployee } = await supabase
            .from('employees')
            .update({ rate_type: 1})
            .eq('id', userId);

        return updatedEmployee;
    } catch (error) {
        console.error('Error fetching subscription:', error);
        throw error;
    }
};

// Función para cancelar la suscripción activa del usuario en Stripe
export const cancelSubscription = async (subscriptionId: string, userId: number) => {
    try {
        // Cancela la suscripción en Stripe
        // const deletedSubscription = await stripe.subscriptions.del(subscriptionId);
        const deletedSubscription = await stripe.subscriptions.cancel(subscriptionId);

        // Puedes verificar el estado de la suscripción cancelada si lo necesitas
        if (deletedSubscription.status === 'canceled') {
            await supabase.from('employees').select('*').eq('id', userId).single();
            await supabase.from('employees').update({ rate_type: 1}).eq('id', userId);
            console.log('Subscription canceled successfully');
        }

        return deletedSubscription;
    } catch (error) {
        console.error('Error cancelling subscription:', error);
        throw error; // Lanza el error para manejarlo en el frontend
    }
};

// Función para obtener la suscripción activa del usuario
export const setRateSelected = async (userId: number) => {
    try {
        await supabase.from('employees').select('*').eq('id', userId).single();
        const { data: updatedEmployee } = await supabase.from('employees').update({ rate_selected: true}).eq('id', userId);
        return updatedEmployee;
    } catch (error) {
        console.error('Error setRateSelected:', error);
        throw error;
    }
}

// Función para obtener la suscripción activa del usuario
export const getRateSelected = async (userId: number) => {
    try {
        const { data: rateSelected } = await supabase.from('employees').select('*').eq('id', userId).eq('rate_selected', true).single();
        return rateSelected;
    } catch (error) {
        console.error('Error getRateSelected:', error);
        throw error;
    }
}
